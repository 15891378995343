import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./layout.css"

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
}

const CarouselPage = () => {
  const data = useStaticQuery(graphql`
    query {
      slide1: file(relativePath: { eq: "slides/slide1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide2: file(relativePath: { eq: "slides/slide2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide3: file(relativePath: { eq: "slides/slide3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide4: file(relativePath: { eq: "slides/slide4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Slider {...settings} className="overflow-hidden">
      <Img fluid={data.slide1.childImageSharp.fluid} />
      <Img fluid={data.slide2.childImageSharp.fluid} />
      <Img fluid={data.slide3.childImageSharp.fluid} />
      <Img fluid={data.slide4.childImageSharp.fluid} />
    </Slider>
  )
}

export default CarouselPage
