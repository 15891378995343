import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CarouselPage from "../components/carousel"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h2>RMB trafikkskole</h2>
    <div style={{ padding: 15 }}>
      <CarouselPage />
    </div>
    <div>
      <p>
        Velkommen til vår trafikkskole med lokaler sentralt i Kristiansand
        sentrum.
      </p>
      <p>
        Vi er to dedikerte kjørelærere, som tilbyr opplæring i førerkort klasse
        B, B96 og BE.
      </p>
      <p style={{ fontStyle: `italic` }}>
        We offer driving lessons in english.
      </p>
    </div>
  </Layout>
)

export default IndexPage
